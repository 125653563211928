const PathConstants = {
    HOME: "/",
    PrivacyPolicy:"/privacy-policy",
    TermsConditions:"/terms-conditions",
    FeaturePage: "/vidsell",
    Reels: "/reels",
    Pricing: "/pricing",
    Strike: "/strike",
    Partner: "/partner",
    AboutUs: "/about-us",
    CaseStudies: "/case-studies",
    CustomerStories: "/case-studies/customer-stories/:url",
    // DemoPage: "/about-us/demo-page",
    // Testing: "/about-us/demo-page/testing",
}

export default PathConstants