import React, { useEffect, useState, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import { SiteLogo } from "../../../ui/SiteLogo";
import TopNotifications from "../../HomePage/TopNotifications/TopNotifications";
import styles from "./header.module.css";
import { PopupContext } from "../../../store/DemoForm-context";
import { IoDocumentTextOutline } from "react-icons/io5";
import { MdVideoSettings } from "react-icons/md";
import { BsLightningFill } from "react-icons/bs";
import { GrDocumentStore } from "react-icons/gr";

export const Header = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showNotification, setShowNotification] = useState(true);

  const handleNotification = (value) => {
    setShowNotification(value);
  };

  const ShowMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };
  const [showFeature, setShowFeature] = useState(false);
  const [showResources, setShowResources] = useState(false);

  const { showPopup } = useContext(PopupContext)

  const isBetween992And1200 = useMediaQuery({ minWidth: 992, maxWidth: 1200 });


  const { pathname } = useLocation();

  useEffect(() => {
    setShowMobileMenu(false);
  }, [pathname]);

  return (
    <>
      <header className={`${styles.headerTop}`}>
        <div className="container">
          <div className={styles.menuWrapper}>
            <div className={styles.logoBox}>
              <Link to={"/"} relative="path">
                <SiteLogo varient="dark" />
              </Link>
            </div>
            <button
              className={`${styles.navBarBtn} ${showMobileMenu ? styles.navBarBtnActive : ""
                }`}
              onClick={ShowMobileMenu}
            >
              <span className={styles.navBarArr}></span>
            </button>
            <div
              className={`${styles.mainMenu} ${showMobileMenu ? styles.showMenu : ""
                } `}
            >
              <ul>
                {isTabletOrMobile ? (
                  <li className={styles.dropdown}>
                    <span onClick={() => { setShowFeature(!showFeature); setShowResources(false) }}>
                      Features
                      <svg
                        strokeWidth="0"
                        viewBox="0 0 448 512"
                        height="14px"
                        width="14px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                      </svg>
                    </span>
                    {showFeature && (
                      <ul>
                        <li>
                          <Link to={"/vidsell"} relative="path">
                            <MdVideoSettings size={20} />
                            VidSell
                          </Link>
                        </li>
                        <li>
                          <Link to={"/reels"} relative="path">
                            <img src="/assets/images/reelsicon.png" alt="reels" />
                            Reels
                          </Link>
                        </li>
                        <li>
                          <Link to={"/strike"} relative="path">
                            <BsLightningFill size={20} />
                            Strike
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                ) : (
                  <li className={styles.dropdown}>
                    <span>
                      Features{" "}
                      <svg
                        strokeWidth="0"
                        viewBox="0 0 448 512"
                        height="14px"
                        width="14px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                      </svg>
                    </span>
                    <ul>
                      <li>
                        <Link to={"/vidsell"} relative="path">
                          <MdVideoSettings size={20} />
                          VidSell
                        </Link>
                      </li>
                      <li>
                        <Link to={"/reels"} relative="path">
                          <img src="/assets/images/reelsicon.png" alt="reels" />
                          Reels
                        </Link>
                      </li>
                      <li>
                        <Link to={"/strike"} relative="path">
                          <BsLightningFill size={20} />
                          Strike
                        </Link>
                      </li>
                    </ul>
                  </li>
                )}
                {isTabletOrMobile ? (
                  <li className={styles.dropdown}>
                    <span onClick={() => { setShowResources(!showResources); setShowFeature(false) }}>
                      Resources
                      <svg
                        strokeWidth="0"
                        viewBox="0 0 448 512"
                        height="14px"
                        width="14px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                      </svg>
                    </span>
                    {showResources && (
                      <ul>
                        <li>
                          <Link
                            to={"https://docs.saleassist.ai"}
                            target="_blank"
                          >
                            <GrDocumentStore size={18} />
                            Documentation{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"case-studies"}
                          >
                            <IoDocumentTextOutline size={20} />
                            Case Studies
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                ) : (
                  <li className={styles.dropdown}>
                    <span>
                      Resources{" "}
                      <svg
                        strokeWidth="0"
                        viewBox="0 0 448 512"
                        height="14px"
                        width="14px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                      </svg>
                    </span>
                    <ul>
                      <li>
                        <Link to={"https://docs.saleassist.ai"} target="_blank">
                          <GrDocumentStore size={18} />
                          Documentation{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"case-studies"}
                        >
                          <IoDocumentTextOutline size={20} />
                          Case Studies
                        </Link>
                      </li>
                    </ul>
                  </li>
                )}
                <li>
                  <Link to={"/pricing"} relative="path">
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link to={"/about-us"} relative="path">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to={"/partner"} relative="path">
                    Partner
                  </Link>
                </li>
              </ul>
              <div className="demo_login_btn">

                <div className={styles.menuRight}>
                  <button
                    className="btn header_book_demo_btn"
                    onClick={showPopup}
                  >
                    <div>
                      <span>Book A Demo</span>
                      <span>Book A Demo</span>
                    </div>
                  </button>
                </div>

                <div className={styles.menuRight}>
                  <a
                    className="btn btn-black btn-icon btn-typ-1"
                    href={"https://my.saleassist.ai/"}
                    title="Login"
                    style={isBetween992And1200 ? { padding: "2px 15px", fontSize: "12px" } : {}}
                  >
                    <div>
                      <span>
                        Login{" "}
                        <em>
                          <img
                            src="/assets/images/icons/ic-login-hover.svg"
                            alt="logon"
                          />
                        </em>
                      </span>
                      <span>
                        Login{" "}
                        <em>
                          <img
                            src="/assets/images/icons/ic-login.svg"
                            alt="login"
                          />
                        </em>
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {showNotification && (
        <TopNotifications notificationStatus={handleNotification} />
      )}
    </>
  );
};
